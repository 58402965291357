import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './UserContext'; // Assuming UserContext provides logged-in user data
import './ChatComponent.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Chat = () => {
    const { user } = useUser(); // Fetching the logged-in user's info
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [file, setFile] = useState(null);
    const [addresses, setAddresses] = useState([]); // State for fetched addresses
    const [showFAQ, setShowFAQ] = useState(false); // Control FAQ visibility

    const faq = [
        {
            question: "Why can’t my balance be transferred because it is frozen?",
            answer: "Hello, if you have an order in progress, such as withdrawal, and the order has not been closed, it will be normal after other orders in progress are completed!"
        },
        {
            question: "How to withdraw and recharge?",
            answer: "Hello! Log in to your registered account, click on assets, and select the cash withdrawal and recharge button below USDT! Follow the prompts to complete."
        },
        {
            question: "What if I forget my password and register my mailbox?",
            answer: "You can choose: forget password which will generate a new password for you and send it to your registered mailbox."
        },
        {
            question: "Where is my balance?",
            answer: "Click on profile, find your corresponding currency, and you can check your balance."
        },
        {
            question: "How to register?",
            answer: "Click on the login menu navigation item and click sign up on the login page."
        }
    ];

    useEffect(() => {
        if (user && user.id) {
            axios.get(`https://antleverage.com/api/chat/${user.id}`)
                .then(res => setMessages(res.data))
                .catch(err => console.error(err));
        }

        // Fetch addresses
        const fetchAddresses = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/addresses');
                setAddresses(response.data);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        fetchAddresses();
    }, [user]);

    const handleSendMessage = async () => {
        const formData = new FormData();
        formData.append('senderId', user.id);
        formData.append('recipientId', 1); // Change this to the actual recipient ID
        formData.append('message', newMessage);
        if (file) formData.append('file', file);

        try {
            const res = await axios.post('https://antleverage.com/api/chat', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessages([...messages, res.data]);
            setNewMessage('');
            setFile(null);
            setShowFAQ(true); // Show FAQ options after sending a message
        } catch (err) {
            console.error(err);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            setFile(selectedFile);
        } else {
            alert('Only image files are allowed');
        }
    };

    const handleFAQClick = (faqAnswer) => {
        const faqMessage = {
            sender_id: null, // Null to indicate it's a system-generated message
            sender_name: "System",
            message: faqAnswer,
            timestamp: new Date().toISOString(),
        };
        setMessages([...messages, faqMessage]);
        setShowFAQ(false); // Hide FAQ after selecting an option
    };

    return (
        <div className="container chat-container">
            {/* Addresses Section */}
            <div className="addresses-section border p-3 mb-3">
                <h5>Available Addresses</h5>
                {addresses.length > 0 ? (
                    <ul>
                        {addresses.map((item) => (
                            <li key={item.option}>
                                {item.option}: {item.address}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No addresses available.</p>
                )}
            </div>

            <div className="chat-messages border p-3 mb-3">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`mb-2 ${msg.sender_id === user.id ? "message-sent text-right" : "message-received text-left"}`}
                    >
                        <div className="message-info">
                            <strong>{msg.sender_id === user.id ? 'You' : msg.sender_name}:</strong>
                        </div>
                        {msg.message && <p className="message-text">{msg.message}</p>}
                        {msg.file_url && <img src={`https://antleverage.com${msg.file_url}`} alt="Attachment" className="img-fluid" />}
                        <small className="text-muted">{new Date(msg.timestamp).toLocaleString()}</small>
                    </div>
                ))}
                {showFAQ && (
                    <div className="faq-options mt-3">
                        <p className="text-muted">Suggested FAQs:</p>
                        {faq.map((item, index) => (
                            <button
                                key={index}
                                className="p-3 btn btn-outline-primary btn-sm m-2"
                                onClick={() => handleFAQClick(item.answer)}
                            >
                                {item.question}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <div className="chat-input form-group">
                <textarea
                    className="form-control mb-2"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
                <input
                    type="file"
                    className="form-control-file mb-2"
                    onChange={handleFileChange}
                    accept="image/*"
                />
                <button
                    className="btn btn-primary"
                    onClick={handleSendMessage}
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default Chat;
